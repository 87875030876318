import React from 'react'
import '../Assets/Style/Footer.less'

const Footer = () => {
  return (
    <div className='footer'>
        <div style={{marginLeft: '444px'}}>
            <div className='contact-us-heading footer-content'>
                Contact Us
            </div>
            <div className='footer-content'>
                <span className='footer-content-titles'> Name  </span>
                <span>Rishabh Kumar</span>
            </div>
            <div className='footer-content'>
                <span className='footer-content-titles'> Email Id:  </span>
                <span>rishu703755@gmail.com</span>
            </div>
            <div className='footer-content'>
                <span className='footer-content-titles'>
                    Phone No.: 
                </span>
                <span>
                    9411668701
                </span>
            </div>
        </div>
    </div>
  )
}

export default Footer