import { Button, FormControl, FormHelperText, Input, InputLabel } from '@mui/material'
import React, { useState } from 'react'
import '../../Assets/Style/jobDetailsForm.less';
import emailjs from '@emailjs/browser';
import  TransitionsModal  from './SubmitDialog'

const JobDetailsForm = () => {

    const [showModal, setShowModal] = useState(false);

    const [state, setState] = useState({
        name : '',
        email : '',
        phoneNo : '',
        alternatePhoneNo : ''
    })

    const handleChange = (e) => {
        let key = e.target.name;
        let val = e.target.value;

        setState({...state, [key]: val});
    }
    
    console.log('state', state)

    const handleSubmit = (e) => {

        e.preventDefault();
        const serviceId = 'service_g8z7jv8';
        const templateId = 'template_gdo1uyp';
        const publicKey = '51Aj4h7Kz1X8LaOla'
    

    const templateParams= {

        form_name : state.name,
        form_email : state.email,
        form_phoneno: state.phoneNo,
        form_alternatephoneno: state.alternatePhoneNo
         
    }

    emailjs.send(serviceId, templateId, templateParams, publicKey)
    .then(response => {
        console.log('Email sent successfully');
        setState({...state, name: '', email: '', phoneNo: '', alternatePhoneNo: ''})
        setShowModal(true);
    })
    .catch(error => {
        console.log('Error Sending Email', error);
    })
}

  return (
    <div >
        <h3>Fill in your details and we'll get in touch</h3>
        <div className='details-form'>
        <FormControl className='details-form-conrtol'>
        
            <InputLabel htmlFor="my-input-name"> Name</InputLabel>
            <Input id="my-input-name" aria-describedby="my-helper-text-name" name='name' value={state.name} onChange={handleChange} />
        
        </FormControl>
        <FormControl className='details-form-conrtol'>
        
            <InputLabel htmlFor="my-input-email">Email address</InputLabel>
            <Input id="my-input-email" aria-describedby="my-helper-text-email" name='email' value={state.email} onChange={handleChange}/>
            <FormHelperText id="my-helper-text-email">We'll never share your email.</FormHelperText>
        
        </FormControl>
        <FormControl className='details-form-conrtol'>
        
            <InputLabel htmlFor="my-input-phoneno"> Phone No.</InputLabel>
            <Input id="my-input-phoneno" aria-describedby="my-helper-text-phoneno"  name='phoneNo' value={state.phoneNo} onChange={handleChange} />
    
        </FormControl>
        <FormControl className='details-form-conrtol'> 
    
            <InputLabel htmlFor="my-input-alternatephoneno"> Alternate Phone No.</InputLabel>
            <Input id="my-input-alternatephoneno" aria-describedby="my-helper-text-alternatephoneno"  name='alternatePhoneNo' value={state.alternatePhoneNo} onChange={handleChange}  />
        </FormControl>
        <Button variant="contained" onClick={handleSubmit}>Submit</Button>
        {showModal && <TransitionsModal />}
    </div>
    </div>
  )
}

export default JobDetailsForm