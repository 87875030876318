import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import '../Assets/Style/Header.less'
import {  Link } from '@mui/material';
// import { Link } from 'react-router-dom';

 const Header = () => {

  const pages = ['Home', 'Services', 'About Us'];

  return ( 
    <Box sx={{ flexGrow: 1 }} id="header-box">
      <AppBar position="static" id="app-header" sx={{ display: 'flex', flexDirection: 'row' }}>
        <Toolbar>
         
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 , display: 'flex', justifyContent: 'left', alignItems: 'center' }} id="app-heading">
            <img src='../../Images/Logo2.png' alt='Logo here...' className='app-logo' />
            Asaani Se Naukri
          </Typography>
          
        </Toolbar>
        <Box sx={{ flexGrow: 1, display: { md: 'flex',  justifyContent: 'center', maxWidth:'57rem' } }} >
            {pages.map((page) => (
              <Link
              href={page==='Home' ? '/' : `#${page}`}
               underline="none"
                key={page}
               className='menu-buttons'
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page}
              </Link>
            ))}
          </Box>
      </AppBar>
    </Box>
  );
}

export default Header;
