import { Card, CardActions, CardContent, Collapse, IconButton, styled, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import '../../Assets/Style/Info.less'

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));


const WhoWeHelp = () => {

   
      const [expanded, setExpanded] = React.useState(false);

      const handleExpandClick = () => {
        setExpanded(!expanded);
      };

  return (
    <Card  className='info-cards' id='card-3'>

      <CardContent>
        <Typography variant="body2" color="text.secondary" className='info-card-heading'>
            Who We Help?
        </Typography>
      </CardContent>
      <CardActions className='info-card-action' disableSpacing>
       
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          
          <Typography paragraph className='info-cards-paragraph'>
           <span className='info-cards-subheading'>Graduation Dropouts:</span> Didn't finish college? We've got you covered.
          </Typography>
          <Typography paragraph className='info-cards-paragraph'>
           <span className='info-cards-subheading'>Unskilled 10th & 12th Graders:</span> Finished school but don't know what to do next? We can help.
          </Typography>
          <Typography paragraph className='info-cards-paragraph'>
            <span className='info-cards-subheading'>Chef and Cooks:</span> Want to work in a kitchen? We can get you started.
          </Typography>
          <Typography paragraph className='info-cards-paragraph'>
            <span className='info-cards-subheading'>Plumbers and Other Tradesmen:</span> Have basic skills but need a job? Come to us.
          </Typography>
          <Typography paragraph className='info-cards-paragraph'>
            <span className='info-cards-subheading'>General Helpers:</span> Need a job helping out in a shop or business? We can connect you.
          </Typography>
          <Typography paragraph className='info-cards-paragraph'>
            <span className='info-cards-subheading'>Aspiring Learners:</span> Want to learn and earn? We'll help you find a job where ypu can do both.
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  )
}

export default WhoWeHelp