import React from 'react'
import {Route, BrowserRouter as Router, Routes} from 'react-router-dom'
import Header from '../Layout/Header'
import Home from '../Pages/Home'
import Footer from '../Layout/Footer'
import JobDetailsForm from '../components/JobDetailsComponent/JobDetailsForm'
const AllRoutes = () => {
  return (
    <div>
      <Header />
      <Router>
        <Routes>
        <Route exact path='/' element= {<Home  />}></Route>
        <Route exact path='/details' element= {<JobDetailsForm  />}></Route>
        </Routes>
      </Router>
 
      <Footer />
   </div>
  )
}

export default AllRoutes