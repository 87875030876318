import React from 'react';
import '../../Assets/Style/AppInfo.less'

const AppInfo = () => {
  return (
    <div className='app-info'>
      <div  className='grid-container'>
          
          <div className='app-info-content'>
            <div id="carouselExampleControls" class="carousel slide" className='app-info-carousel' data-ride="carousel">
              <div class="carousel-inner" className='app-info-carousel-item'>
                <div class="carousel-item active">
                  <h3>Naukri Made Simple Now !!</h3>
                  <p className='first-para-content'>Are you looking for a job but don't have the right skills? </p>
                  <p>No worries! At Asaani se Naukri, we help people like you get the training you need and connect you with employers who are looking for hardworking individuals.</p> 	
                </div>
                <div class="carousel-item">
                  <h3>Our Mission</h3>
                  <p className=''>We believe everyone deserves a chance to work and succeed. Our goal is to give you the skills you need and help you to find a job </p>
                </div>
    
              </div>
              <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
              </a>
              <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
              </a>
          </div>
            
        </div>
        
      </div>
    </div>
  )
}

export default AppInfo