import React from 'react';
import '../../Assets/Style/Services.less';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import Typography from '@mui/material/Typography';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import PostAddIcon from '@mui/icons-material/PostAdd';


const Services = () => {
  return (
    <div className='services'>
        {/* <h2>Our Services</h2> */}
       
        <div className='service-card-background'> <div className='service-card-second-background'></div></div>

        <div className='services-cards'>
          
        <Card sx={{ width: 200, marginRight: '12px' }} variant='outlined' className="service-individual-cards">
      <CardContent className="service-card-content">
        <div className='service-card-content-header'>
      <PersonSearchIcon />
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Find Job ?
      
        </Typography>
        </div>
        <div className='service-card-content-body'>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          "Work Dhoondo, Sapne Sajao."
      
        </Typography>
        </div>
      </CardContent>
      <CardActions className='service-card-actions'>
        <a href='/details'>Fill your details now >></a>
      </CardActions>
      </Card>
      <Card sx={{ width: 200, marginRight: '12px' }} variant='outlined' className="service-individual-cards">
      <CardContent className="service-card-content">
        <div className='service-card-content-header'>
      <PostAddIcon />
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Post Job ?
    
        </Typography>
        </div>
        <div className='service-card-content-body'>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          "Post Karein, Sahi Candidate Paayein."
      
        </Typography>
        </div>
      </CardContent>
      <CardActions className='service-card-actions'>
        <a href='/details'>Fill job details now >></a>
      </CardActions>
      </Card>
           
        </div>

    </div>
  )
}

export default Services