import { Card, CardActions, CardContent, Collapse, IconButton, styled, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useState } from 'react'
import '../../Assets/Style/Info.less'

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));


const ServicesInfo = () => {

   
      const [expanded, setExpanded] = useState(false);

      const handleExpandClick = () => {
        setExpanded(!expanded);
      };

  return (
    <Card  className='info-cards' id='card-1'>

      <CardContent>
        <Typography variant="body2" color="text.secondary" className='info-card-heading'>
            Services Information
        </Typography>
      </CardContent>
      <CardActions className='info-card-action' disableSpacing>
       
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          
          <Typography paragraph variant="body2" color="text.secondary">
           For Employees
          </Typography>
          <Typography paragraph className='info-cards-paragraph'>
            Skill Training: Learn new skills that will help you get job.
            Job Placement: We help you find a job that suits you.
            Career Guidance: We guide you to choose the right path for your future. 
          </Typography>
          <Typography paragraph variant="body2" color="text.secondary">
           For Employers:
          </Typography>
          <Typography paragraph className='info-cards-paragraph'>
           Need reliable and hardworking employees? Asaani se Naukri provides trained and motivated candidates ready to work.
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  )
}

export default ServicesInfo