import React from 'react'
import AppInfo from '../components/HomeComponents/AppInfo'
import Services from '../components/HomeComponents/Services'
import ServicesInfo from '../components/HomeComponents/ServicesInfo'
import HowItWroks from '../components/HomeComponents/HowItWroks'
import WhoWeHelp from '../components/HomeComponents/WhoWeHelp'
import '../Assets/Style/Home.less'

const Home = () => {
  return (
    <div>
      <section id='Services'><Services /></section>
      
      <div className='info-section'>
        <ServicesInfo  />
        <HowItWroks />
        <WhoWeHelp />
      </div>
      <section id='About Us'><AppInfo  /></section>
      
        
    </div>
  )
}

export default Home